import React, { useContext, useEffect } from 'react';
import AuthPage from '@leuven2030/framework/Auth/AuthPage';
import UserContext from '@leuven2030/framework/User/UserContext';
import MonitorMemberLocked from '@leuven2030/framework/Monitor/MonitorMemberLocked';
import MonitorMemberInvitation from '@leuven2030/framework/Monitor/MonitorMemberInvitation';
import Loader from '@leuven2030/framework/Loader/Loader';
import 'twin.macro';
import PageLayout from '@leuven2030/monitor/Page/PageLayout';
import { useMonitorGroupQuery } from '@leuven2030/framework/Monitor/MonitorGraphqlApollo';
import ErrorApollo from '@leuven2030/framework/Error/ErrorApollo';
const MonitorLayout = ({ children }) => {
  const { data, loading, refetch, error } = useMonitorGroupQuery();
  const { user, ready } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      return;
    }
    refetch();
  }, [user]);

  function getContent() {
    if (!ready || loading) {
      return <MonitorLoader />;
    }

    if (!user) {
      return <AuthPage />;
    }

    if (error) {
      return <ErrorApollo error={error} />;
    }

    if (!data) {
      return <MonitorLoader />;
    }

    const {
      monitor: {
        group: { invitation, membership }
      }
    } = data;

    if (invitation && !membership) {
      return <MonitorMemberInvitation />;
    }
    if (!membership) {
      return <MonitorMemberLocked />;
    }
    return children;
  }
  return <PageLayout>{getContent()}</PageLayout>;
};

const MonitorLoader = () => {
  return (
    <div tw="py-24 px-12 w-full h-full flex items-center justify-center">
      <Loader tw="m-auto " size="lg" />
    </div>
  );
};

export default MonitorLayout;
