import Button from '@leuven2030/framework/Button/Button';
import {
  useMonitorGroupQuery,
  useMonitorInvitationAcceptMutation
} from '@leuven2030/framework/Monitor/MonitorGraphqlApollo';
import MonitorStatus from '@leuven2030/framework/Monitor/MonitorStatus';
import React from 'react';
import 'twin.macro';
import Illustration from './MonitorMemberInvitation.svg';

const MonitorMemberInvitation = () => {
  //const {data: } = useQuery(MonitorMemberSubscriptionQuery);
  const [acceptInvitation, acceptInvitationState] =
    useMonitorInvitationAcceptMutation();
  const workspace = useMonitorGroupQuery();
  const loading = acceptInvitationState.loading || workspace.loading;
  return (
    <MonitorStatus Illustration={Illustration}>
      <h2 tw="text-3xl mb-2">You are invited!</h2>
      <p>Join this workspace to start seeing our reports</p>
      <Button
        loading={loading}
        variant="contained"
        tw="mt-6"
        onClick={async () => {
          const groupId = workspace.data?.monitor?.group?.id;
          await acceptInvitation({
            variables: {
              groupId
            }
          });
          await workspace.refetch();
        }}
      >
        Accept
      </Button>
    </MonitorStatus>
  );
};

export default MonitorMemberInvitation;
