import React, { FC, useContext } from 'react';
import Auth, { AuthProps } from '@leuven2030/framework/Auth/Auth';
import 'twin.macro';
import { PageContext } from '@leuven2030/framework/Page/Page';
import Image from '@leuven2030/framework/Image/Image';
import get from 'lodash/get';
type Props = AuthProps;

export const AuthPage: FC<Props> = ({ initialAuthState }) => {
  const { prismic } = useContext(PageContext);

  const cover =
    get(prismic, 'auth_config.cover.url') ||
    'https://unsplash.com/photos/mFnbFaCIu1I/download?force=true&w=2400';
  return (
    <div tw="min-h-screen bg-white flex">
      <div tw="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div tw="mx-auto w-full max-w-sm space-y-6">
          <Auth initialAuthState={initialAuthState} />
        </div>
      </div>
      <div tw="hidden lg:block relative w-0 flex-1 bg-gray-200">
        <Image
          tw="absolute inset-0 h-full w-full object-cover"
          imgixParams={{ w: 1500, h: 1500 }}
          src={cover}
          alt=""
        />
      </div>
    </div>
  );
};

export default AuthPage;
