import { StyleContentContainer } from '@leuven2030/framework/Style/StyleContent';
import React from 'react';
import 'twin.macro';
const MonitorStatus = ({ Illustration, children }) => {
  return (
    <div css={StyleContentContainer} tw="py-12">
      <div tw="flex items-center bg-primary-500 rounded-xl overflow-hidden">
        <div tw="text-white max-w-md p-6">{children}</div>
        <div tw="flex flex-1 justify-center bg-gray-700 p-6">
          <Illustration />
        </div>
      </div>
    </div>
  );
};

export default MonitorStatus;
