import MonitorStatus from '@leuven2030/framework/Monitor/MonitorStatus';
import React, { useContext } from 'react';
import 'twin.macro';
import Illustration from './MonitorMemberLocked.svg';
const MonitorMemberLocked = () => {
  return (
    <MonitorStatus Illustration={Illustration}>
      <h1 tw="text-4xl mb-3">Not a member</h1>
      <p>
        You will need to be invited to view the contents of this website. To
        request access you can contact us at....
      </p>
    </MonitorStatus>
  );
};

export default MonitorMemberLocked;
