/*This is a generated document*/
/* eslint-disable */
import * as Types from '../Graphql/GraphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  join__FieldSet: any;
};






export type Content = {
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  uid: Scalars['String'];
};

export type ContentBlock = {
  id: Scalars['ID'];
};

export type ContentDraft = {
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  versionId?: Maybe<Scalars['String']>;
};

export type ContentQuery = {
  __typename?: 'ContentQuery';
  get?: Maybe<Scalars['String']>;
};

export type ContentVersion = {
  createdAt?: Maybe<Scalars['String']>;
  createdBy: User;
  id: Scalars['ID'];
};


export type Discussion = {
  __typename?: 'Discussion';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  discussion: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  storeType: Scalars['String'];
};

export type DiscussionQuery = {
  __typename?: 'DiscussionQuery';
  list: Array<Discussion>;
};

export type Group = {
  __typename?: 'Group';
  createdBy: User;
  id: Scalars['ID'];
  invitation?: Maybe<GroupInvitation>;
  members?: Maybe<Array<Maybe<User>>>;
  membership?: Maybe<GroupMembership>;
  profile?: Maybe<GroupProfileQuery>;
  report?: Maybe<MonitorReportQuery>;
};

export type GroupInvitation = {
  __typename?: 'GroupInvitation';
  email: Scalars['String'];
  id: Scalars['ID'];
  status: GroupInvitationStatus;
};

export type GroupInvitationMutation = {
  __typename?: 'GroupInvitationMutation';
  accept?: Maybe<Group>;
  create?: Maybe<GroupInvitation>;
};


export type GroupInvitationMutationAcceptArgs = {
  groupId: Scalars['ID'];
};


export type GroupInvitationMutationCreateArgs = {
  email: Scalars['String'];
};

export enum GroupInvitationStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING'
}

export type GroupMembership = {
  __typename?: 'GroupMembership';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
};

export type GroupMutation = {
  __typename?: 'GroupMutation';
  create?: Maybe<Group>;
  invitation?: Maybe<GroupInvitationMutation>;
};


export type GroupMutationCreateArgs = {
  name: Scalars['String'];
};

export type GroupProfile = {
  __typename?: 'GroupProfile';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GroupProfileQuery = {
  __typename?: 'GroupProfileQuery';
  current: GroupProfile;
  pending?: Maybe<GroupProfile>;
  published?: Maybe<GroupProfile>;
};

export type GroupQuery = {
  __typename?: 'GroupQuery';
  get?: Maybe<Group>;
  list?: Maybe<Array<Maybe<Group>>>;
};


export type GroupQueryGetArgs = {
  id: Scalars['ID'];
};

export type MonitorQuery = {
  __typename?: 'MonitorQuery';
  group?: Maybe<Group>;
};

export type MonitorReport = {
  __typename?: 'MonitorReport';
  embedToken: MonitorReportToken;
  embedUrl: Scalars['String'];
  id: Scalars['ID'];
};

export type MonitorReportQuery = {
  __typename?: 'MonitorReportQuery';
  get?: Maybe<MonitorReport>;
};


export type MonitorReportQueryGetArgs = {
  id: Scalars['String'];
};

export type MonitorReportToken = {
  __typename?: 'MonitorReportToken';
  expiration: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  group?: Maybe<GroupMutation>;
  offer?: Maybe<OfferMutation>;
  plan?: Maybe<PlanMutation>;
  storage?: Maybe<StorageMutation>;
};

export type Offer = Content & {
  __typename?: 'Offer';
  content: OfferContent;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  uid: Scalars['String'];
};

export type OfferContent = {
  __typename?: 'OfferContent';
  draft: OfferData;
  id: Scalars['ID'];
  pending?: Maybe<OfferData>;
  published?: Maybe<OfferData>;
};

export type OfferData = ContentVersion & {
  __typename?: 'OfferData';
  createdAt?: Maybe<Scalars['String']>;
  createdBy: User;
  details: OfferDetails;
  id: Scalars['ID'];
  versionId?: Maybe<Scalars['String']>;
};

export type OfferDetails = {
  __typename?: 'OfferDetails';
  about?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cover?: Maybe<StorageObject>;
  deadline?: Maybe<Scalars['String']>;
  gallery?: Maybe<StorageCollection>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type OfferImagesMutation = {
  __typename?: 'OfferImagesMutation';
  add?: Maybe<StorageObject>;
};

export type OfferMutation = {
  __typename?: 'OfferMutation';
  create?: Maybe<Offer>;
  images?: Maybe<OfferImagesMutation>;
  update?: Maybe<OfferDetails>;
};


export type OfferMutationCreateArgs = {
  title: Scalars['String'];
};


export type OfferMutationUpdateArgs = {
  contentBlockId: Scalars['String'];
  data?: Maybe<OfferUpdateData>;
};

export type OfferQuery = {
  __typename?: 'OfferQuery';
  get?: Maybe<Offer>;
  list?: Maybe<Array<Maybe<Offer>>>;
};


export type OfferQueryGetArgs = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type OfferUpdateData = {
  about?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  coverId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PlanEvent = {
  __typename?: 'PlanEvent';
  about?: Maybe<Scalars['String']>;
  ends?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meeting_link?: Maybe<Scalars['String']>;
  starts?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PlanEventMutation = {
  __typename?: 'PlanEventMutation';
  create?: Maybe<PlanEvent>;
  update?: Maybe<PlanEvent>;
};


export type PlanEventMutationUpdateArgs = {
  data?: Maybe<PlanEventUpdateData>;
  id: Scalars['String'];
};

export type PlanEventQuery = {
  __typename?: 'PlanEventQuery';
  get?: Maybe<PlanEvent>;
  list?: Maybe<Array<Maybe<PlanEvent>>>;
};


export type PlanEventQueryGetArgs = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type PlanEventUpdateData = {
  about?: Maybe<Scalars['String']>;
  ends?: Maybe<Scalars['String']>;
  meeting_link?: Maybe<Scalars['String']>;
  starts?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PlanMutation = {
  __typename?: 'PlanMutation';
  event?: Maybe<PlanEventMutation>;
};

export type PlanQuery = {
  __typename?: 'PlanQuery';
  event?: Maybe<PlanEventQuery>;
};

export type Query = {
  __typename?: 'Query';
  content?: Maybe<ContentQuery>;
  discussion: DiscussionQuery;
  group?: Maybe<GroupQuery>;
  monitor?: Maybe<MonitorQuery>;
  offer?: Maybe<OfferQuery>;
  plan?: Maybe<PlanQuery>;
  storage?: Maybe<StorageQuery>;
  user?: Maybe<UserQuery>;
};

export type StorageCollection = {
  __typename?: 'StorageCollection';
  id: Scalars['ID'];
  objects?: Maybe<Array<Maybe<StorageObject>>>;
};

export type StorageCollectionMutation = {
  __typename?: 'StorageCollectionMutation';
  addObject?: Maybe<StorageObject>;
  collectionId: Scalars['String'];
};


export type StorageCollectionMutationAddObjectArgs = {
  objectId: Scalars['String'];
};

export type StorageMutation = {
  __typename?: 'StorageMutation';
  collection?: Maybe<StorageCollectionMutation>;
  object?: Maybe<StorageObjectMutation>;
};


export type StorageMutationCollectionArgs = {
  id: Scalars['String'];
};

export type StorageObject = {
  __typename?: 'StorageObject';
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imgixUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};


export type StorageObjectImgixUrlArgs = {
  fit?: Maybe<Scalars['String']>;
  h?: Maybe<Scalars['Int']>;
  w?: Maybe<Scalars['Int']>;
};

export type StorageObjectMutation = {
  __typename?: 'StorageObjectMutation';
  create?: Maybe<StorageObject>;
};


export type StorageObjectMutationCreateArgs = {
  name: Scalars['String'];
  type: Scalars['String'];
};

export type StorageObjectQuery = {
  __typename?: 'StorageObjectQuery';
  get?: Maybe<StorageObject>;
};


export type StorageObjectQueryGetArgs = {
  id: Scalars['String'];
};

export type StorageQuery = {
  __typename?: 'StorageQuery';
  object?: Maybe<StorageObjectQuery>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
};

export type UserQuery = {
  __typename?: 'UserQuery';
  me?: Maybe<User>;
};


export enum Join__Graph {
  Content = 'CONTENT',
  Discussion = 'DISCUSSION',
  Group = 'GROUP',
  Monitor = 'MONITOR',
  Offer = 'OFFER',
  Plan = 'PLAN',
  Storage = 'STORAGE',
  User = 'USER'
}

export type MonitorReportGetQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type MonitorReportGetQuery = (
  { __typename?: 'Query' }
  & { monitor?: Types.Maybe<(
    { __typename?: 'MonitorQuery' }
    & { group?: Types.Maybe<(
      { __typename?: 'Group' }
      & { report?: Types.Maybe<(
        { __typename?: 'MonitorReportQuery' }
        & { get?: Types.Maybe<(
          { __typename?: 'MonitorReport' }
          & Pick<Types.MonitorReport, 'embedUrl'>
          & { embedToken: (
            { __typename?: 'MonitorReportToken' }
            & Pick<Types.MonitorReportToken, 'token'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type MonitorGroupQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MonitorGroupQuery = (
  { __typename?: 'Query' }
  & { monitor?: Types.Maybe<(
    { __typename?: 'MonitorQuery' }
    & { group?: Types.Maybe<(
      { __typename?: 'Group' }
      & Pick<Types.Group, 'id'>
      & { invitation?: Types.Maybe<(
        { __typename?: 'GroupInvitation' }
        & Pick<Types.GroupInvitation, 'id' | 'status'>
      )>, membership?: Types.Maybe<(
        { __typename?: 'GroupMembership' }
        & Pick<Types.GroupMembership, 'id'>
      )> }
    )> }
  )> }
);

export type MonitorInvitationAcceptMutationVariables = Types.Exact<{
  groupId: Types.Scalars['ID'];
}>;


export type MonitorInvitationAcceptMutation = (
  { __typename?: 'Mutation' }
  & { group?: Types.Maybe<(
    { __typename?: 'GroupMutation' }
    & { invitation?: Types.Maybe<(
      { __typename?: 'GroupInvitationMutation' }
      & { accept?: Types.Maybe<(
        { __typename?: 'Group' }
        & Pick<Types.Group, 'id'>
        & { membership?: Types.Maybe<(
          { __typename?: 'GroupMembership' }
          & Pick<Types.GroupMembership, 'id'>
        )>, invitation?: Types.Maybe<(
          { __typename?: 'GroupInvitation' }
          & Pick<Types.GroupInvitation, 'id' | 'status'>
        )> }
      )> }
    )> }
  )> }
);


export const MonitorReportGetDocument = gql`
    query MonitorReportGet($id: String!) {
  monitor {
    group {
      report {
        get(id: $id) {
          embedToken {
            token
          }
          embedUrl
        }
      }
    }
  }
}
    `;

/**
 * __useMonitorReportGetQuery__
 *
 * To run a query within a React component, call `useMonitorReportGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitorReportGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitorReportGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMonitorReportGetQuery(baseOptions: Apollo.QueryHookOptions<MonitorReportGetQuery, MonitorReportGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonitorReportGetQuery, MonitorReportGetQueryVariables>(MonitorReportGetDocument, options);
      }
export function useMonitorReportGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonitorReportGetQuery, MonitorReportGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonitorReportGetQuery, MonitorReportGetQueryVariables>(MonitorReportGetDocument, options);
        }
export type MonitorReportGetQueryHookResult = ReturnType<typeof useMonitorReportGetQuery>;
export type MonitorReportGetLazyQueryHookResult = ReturnType<typeof useMonitorReportGetLazyQuery>;
export type MonitorReportGetQueryResult = Apollo.QueryResult<MonitorReportGetQuery, MonitorReportGetQueryVariables>;
export const MonitorGroupDocument = gql`
    query MonitorGroup {
  monitor {
    group {
      id
      invitation {
        id
        status
      }
      membership {
        id
      }
    }
  }
}
    `;

/**
 * __useMonitorGroupQuery__
 *
 * To run a query within a React component, call `useMonitorGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitorGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitorGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useMonitorGroupQuery(baseOptions?: Apollo.QueryHookOptions<MonitorGroupQuery, MonitorGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonitorGroupQuery, MonitorGroupQueryVariables>(MonitorGroupDocument, options);
      }
export function useMonitorGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonitorGroupQuery, MonitorGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonitorGroupQuery, MonitorGroupQueryVariables>(MonitorGroupDocument, options);
        }
export type MonitorGroupQueryHookResult = ReturnType<typeof useMonitorGroupQuery>;
export type MonitorGroupLazyQueryHookResult = ReturnType<typeof useMonitorGroupLazyQuery>;
export type MonitorGroupQueryResult = Apollo.QueryResult<MonitorGroupQuery, MonitorGroupQueryVariables>;
export const MonitorInvitationAcceptDocument = gql`
    mutation MonitorInvitationAccept($groupId: ID!) {
  group {
    invitation {
      accept(groupId: $groupId) {
        id
        membership {
          id
        }
        invitation {
          id
          status
        }
      }
    }
  }
}
    `;
export type MonitorInvitationAcceptMutationFn = Apollo.MutationFunction<MonitorInvitationAcceptMutation, MonitorInvitationAcceptMutationVariables>;

/**
 * __useMonitorInvitationAcceptMutation__
 *
 * To run a mutation, you first call `useMonitorInvitationAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitorInvitationAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitorInvitationAcceptMutation, { data, loading, error }] = useMonitorInvitationAcceptMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useMonitorInvitationAcceptMutation(baseOptions?: Apollo.MutationHookOptions<MonitorInvitationAcceptMutation, MonitorInvitationAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MonitorInvitationAcceptMutation, MonitorInvitationAcceptMutationVariables>(MonitorInvitationAcceptDocument, options);
      }
export type MonitorInvitationAcceptMutationHookResult = ReturnType<typeof useMonitorInvitationAcceptMutation>;
export type MonitorInvitationAcceptMutationResult = Apollo.MutationResult<MonitorInvitationAcceptMutation>;
export type MonitorInvitationAcceptMutationOptions = Apollo.BaseMutationOptions<MonitorInvitationAcceptMutation, MonitorInvitationAcceptMutationVariables>;